import { addValidator } from '@winvestor/wds'
import { defineCustomElements } from '@winvestor/wds/loader'
import { ActiveRecordValidator } from '@/validators/active_record'

import turbolinks from 'turbolinks'
import mrujs from 'mrujs'

import { CustomNavigationAdapter } from '@/lib/mrujs/custom_navigation_adapter'

import '@/channels/common'

addValidator('ActiveRecord', ActiveRecordValidator)
void defineCustomElements()

mrujs.inputChangeSelector += ', wds-checkbox[data-remote]'

if (!('mrujs' in window)) mrujs.start({ plugins: [CustomNavigationAdapter()] })
if (!('Turbolinks' in window)) turbolinks.start()

mrujs.navigationAdapter.disconnect?.()