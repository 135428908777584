import {
  Locateable,
  FetchRequestInterface,
  FetchResponseInterface,
  VisitAction,
} from 'mrujs/types'
import { NavigationAdapterInterface } from 'mrujs/src/navigationAdapter'

const ALLOWABLE_ACTIONS = ['advance', 'replace', 'restore']

export function CustomNavigationAdapter(): NavigationAdapterInterface {
  const obj = {
    name: 'CustomNavigationAdapter',
    connect,
    disconnect,
    prefetch,
    cacheContains,
    navigate,
  }

  return obj
}

function connect(): void {
  document.addEventListener('ajax:complete', beforeNavigation as EventListener)
  document.addEventListener(
    'ajax:beforeNavigation',
    navigateViaEvent as EventListener
  )
}

function disconnect(): void {
  document.removeEventListener(
    'ajax:complete',
    beforeNavigation as EventListener
  )
  document.removeEventListener(
    'ajax:beforeNavigation',
    navigateViaEvent as EventListener
  )
}

function useTurbolinks() {
  if (window.Turbolinks == null) return false
  if (window.Turbolinks.supported !== true) return false

  return window.Turbolinks
}

function beforeNavigation(event: CustomEvent): void {
  if (event.defaultPrevented) return

  dispatch.call(event.detail.element, 'ajax:beforeNavigation', {
    detail: { ...event.detail },
  })
}

function navigateViaEvent(event: CustomEvent): void {
  if (event.defaultPrevented) return

  const { element, fetchResponse, fetchRequest } = event.detail

  if (!shouldNavigate(element, fetchResponse)) return

  navigate(element, fetchRequest, fetchResponse)
}

function shouldNavigate(
  element: HTMLElement,
  fetchResponse: FetchResponseInterface
) {
  if (element.dataset.ujsNavigate === 'false') return false
  if (fetchResponse == null) return false

  if (!fetchResponse.isHtml) return false

  if (fetchResponse.succeeded && !fetchResponse.redirected) {
    return false
  }

  return true
}

function navigate(
  element: HTMLElement,
  request: FetchRequestInterface,
  response: FetchResponseInterface,
  action?: VisitAction
) {
  action = action ?? determineAction(element)

  let location = expandUrl(window.location.href)

  if (request?.isGetRequest) location = request.url
  if (response.redirected) location = response.location

  const adapter = useTurbolinks()

  if (!adapter) return

  adapter.clearCache()

  preventDoubleVisit(response, location, action)
}

function preventDoubleVisit(
  response: FetchResponseInterface,
  location: Locateable,
  action: VisitAction
) {
  const adapter = useTurbolinks()

  if (!adapter) return

  response
    .html()
    .then((html: string) => {
      prefetch({ html, url: location })
      action = 'restore'
      adapter.visit(location, { action })
    })
    .catch((error: any) => console.error(error))
}

function determineAction(element: HTMLElement) {
  let action = element.dataset.turbolinksAction ?? 'advance'

  if (!ALLOWABLE_ACTIONS.includes(action)) {
    action = 'advance'
  }

  return action as VisitAction
}

function prefetch({ html, url }: { html: string; url: Locateable }) {
  const expandedUrl = expandUrl(url)
  const snapshot = generateSnapshotFromHtml(html)
  putSnapshotInCache(expandedUrl, snapshot)
}

function findSnapshotCache() {
  const adapter = useTurbolinks()
  if (adapter) return adapter.controller.cache
  return undefined
}

function putSnapshotInCache(location: Locateable, snapshot: string) {
  if (snapshot === '') return

  const adapter = useTurbolinks()

  if (!adapter) return

  const snapshotCache = findSnapshotCache()
  snapshotCache?.put(expandUrl(location), snapshot)
}

function generateSnapshotFromHtml(html: string) {
  const adapter = useTurbolinks()

  if (!adapter) return ''

  return adapter.Snapshot.wrap(html) ?? ''
}

function dispatch(
  this: Node | EventTarget,
  name: string,
  options: CustomEventInit = {}
) {
  const event = new CustomEvent(name, {
    ...{ bubbles: true, cancelable: true },
    ...options,
  })
  this.dispatchEvent(event)
  return event
}

function expandUrl(locateable: Locateable) {
  return new URL(locateable.toString(), document.baseURI)
}

function cacheContains(url: Locateable) {
  const expandedUrl = expandUrl(url)
  const snapshotCache = findSnapshotCache()
  return snapshotCache?.has(expandedUrl) ?? false
}
